<template>
  <div class="collectItem">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="slideBox">
        <template>
          <div v-for="item in list" :key="item.id" class="collect-item">
            <div v-if="shoRemoveIcon" class="remove-mask">
              <span class="remove-icon" @click="removeCollect(item)"></span>
            </div>
            <VideoBox @click.native="openVideo(item)" class="slideItem" :videoInfo="item" />
          </div>
        </template>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { queryfavorites } from '@/api/mine';
import { careCollectVideo } from '@/api/user';
import PullRefresh from '@/components/PullRefresh';
import VideoBox from '@/components/VideoBox';

export default {
  name: 'tagVideo',
  components: {
    PullRefresh,
    VideoBox,
  },
  props: {
    shoRemoveIcon: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tagId: '',
      tagName: '',
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据,
      vodeoType: 'movie',
    };
  },
  created() {
    this.tagId = this.$route.query.id;
    this.tagName = this.$route.query.name;
    this.getList();
  },
  // watch: {
  //   activeName(newVal, oldVal) {
  //     if (newVal != oldVal) {
  //       this.vodeoType = newVal;
  //     }
  //   },
  // },
  methods: {
    async removeCollect(item) {
      let req = {
        isCollect: false,
        objID: item.id,
        type: this.type == 'long' ? 'movie' : 'video',
      };
      let res = await this.$Api(careCollectVideo, req);
      if (res.code === 200) {
        Toast('取消收藏');
        this.getList('refresh');
      }
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$store.getters.userInfo.uid,
        type: this.type == 'long' ? 'movie' : 'video',
      };
      try {
        let res = await this.$Api(queryfavorites, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.collect-item {
  position: relative;
  margin-bottom: 6px;
}
.remove-mask {
  position: absolute;
  background: rgba(251, 231, 237, 0.8);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  z-index: 99999;
  border-radius: 4px;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url('./../../../../assets/png/del.png') no-repeat;
    background-size: 100% 100%;
  }
}
/deep/ .videoBox .coverBox {
  height: 92px;
  width: 164px;
}
.collectItem {
  height: calc(100vh - 60px);
  padding: 10px 20px 0;
  .slideBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .slideItem {
      width: 164px;
    }
  }
}
</style>
