<template>
  <div class="collectionInfo">
    <div class="back flex-center-center" @click="$router.go(-1)">
      <svg-icon icon-class="headArrow" />
    </div>
    <img v-if="!shoRemoveIcon" @click="shoRemoveIcon = true" class="clear" src="@/assets/png/del_clear.png" />
    <img v-if="shoRemoveIcon" @click="shoRemoveIcon = false" class="clear" src="@/assets/png/del_clear.png" />
    <!-- tab -->
    <van-tabs
      v-model="activeName"
      :class="{ short: activeName == 1 }"
      class="homeTab"
      title-active-color="rgb(0,0,0)"
      title-inactive-color="rgb(142,142,142)"
      color="#ff0d2c"
      animated
      :swipeable="true"
      @change="change"
    >
      <van-tab :title="item.label" v-for="item in tabList" :key="item.value">
        <VideoList :shoRemoveIcon="shoRemoveIcon" :topicId="topicId" :type="item.value" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import VideoList from './videoList/index.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'myCollection',
  components: {
    VideoList,
  },
  computed: {
    ...mapGetters({
      collectionScroll: 'collectionScroll',
    }),
  },
  data() {
    return {
      topicId: '',
      shoRemoveIcon: false,
      activeName: '0',
      oldActive: 0,
      tabList: [
        {
          label: '长视频',
          value: 'long',
        },
        {
          label: '短视频',
          value: 'short',
        },
      ], // tab列表
    };
  },
  methods: {
    showMask() {
      this.shoRemoveIcon = true;
    },
    change(index) {
      console.log(index);
      let dom = document.querySelectorAll('.collectItem');
      console.log(this.collectionScroll);
      let scrollTopArr = this.collectionScroll.scrollArr;
      console.log(this.oldActive);
      if (this.oldActive) {
        console.log(1111);
        let scrollTop0 = dom[index].scrollTop;
        if (scrollTop0 == 0) {
          // dom[index].scrollTop = scrollTopArr[index];
        }
        // globalStore.setScrollInfo(route.path, index);
        // globalStore.updateScroll(route.path, 'scrollArr', dom[index].scrollTop, index);
      } else {
        console.log(2222);
        console.log(dom);
        let scrollTop0 = dom[0].scrollTop;
        scrollTopArr[0] = scrollTop0;
        this.$store.commit('app/SET_COLLECTSCLL', {
          className: 'collectItem',
          scrollTop: scrollTop0,
          isTab: true,
          scrollArr: scrollTopArr,
          curIndex: 0,
        });
        // console.log(this.collectionScroll.scrollArr)
        if (scrollTop0 == 0) {
          dom[0].scrollTop = scrollTopArr[0];
        }
        // globalStore.setScrollInfo(route.path, 0);
        // globalStore.updateScroll(route.path, 'scrollArr', dom[0].scrollTop, 0);
      }
      setTimeout(() => {
        this.oldActive = index;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .short .videoBox .coverBox {
  height: 246px !important;
}
.clear {
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  z-index: 9999;
  position: absolute;
}
.collectionInfo {
  height: 100%;
  .back {
    position: absolute;
    left: 14px;
    top: 14px;
    height: 19px;
    width: 19px;
    z-index: 1;
    > svg {
      height: 16px;
      width: 13.3px;
    }
  }
}
/deep/ .van-tabs {
  .van-tabs__wrap {
    padding: 0 130px;
  }
  .van-tab__text {
    font-size: 14px;
  }
  .van-tab--active {
    font-weight: 600;
  }
  .van-tabs__line {
    width: 14px;
    height: 6px;
    background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
    bottom: 20px;
  }
}
</style>
